var $ = require('jquery');
var cookies = require('browser-cookies');

$.behaviors('#accept-all-cookies', function(_button) {
    var button = $(_button);

    button.on('click', function() {
        let container = $('.cookie-consent-container');
        container.hide();
        cookies.set('amara_cookie_consent', '1', { expires: 365, path: '/' });
        unlock_window();
        window.clarity('consent');
    });
});

$.behaviors('#manage-cookies', function(_button) {
    var button = $(_button);

    button.on('click', function() {
        let main_container = $('.cookie-consent-container');
        main_container.hide();

        let details_container = $('.cookie-consent-details-container');
        details_container.removeClass('hidden');
    });
});

$.behaviors('#cookie-consent-details-toggle', function(_button) {
    var button = $(_button);

    button.on('click', function(e) {
        e.preventDefault();
    });
});

$.behaviors('#accept-manage-cookies', function(_button) {
    var button = $(_button);

    button.on('click', function() {
        let details_container = $('.cookie-consent-details-container');
        details_container.hide();

        var toggle = $('#cookie-consent-details-input')[0];
        var cookie_value = toggle.checked ? 1 : 0;
        cookies.set('amara_cookie_consent', String(cookie_value), { expires: 365, path: '/' });
        if (cookie_value === 1) {
            window.clarity('consent');
        }
        unlock_window()
    });
});

$.behaviors('#cancel-manage-cookies', function(_button) {
    var button = $(_button);

    button.on('click', function() {
        cookies.set('amara_defer_ask_for_cookie_consent', '1', { expires: 1, path: '/' });

        let details_container = $('.cookie-consent-details-container');
        details_container.hide();
        unlock_window()
    });
});

function lock_scroll_position() {
    var scrollPosition = [
      self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
      self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
    ];

    var body = $('body');
    body.data('scroll-position', scrollPosition);
    body.data('previous-overflow', body.css('overflow'));
    body.css('overflow', 'hidden');
    window.scrollTo(scrollPosition[0], scrollPosition[1]);
}

function unlock_scroll_position() {
    var body = $('body');
    var scrollPosition = body.data('scroll-position');
    body.css('overflow', body.data('previous-overflow'));
    window.scrollTo(scrollPosition[0], scrollPosition[1])
}

function remove_cover() {
    var cover = $('.cookie-consent-cover');
    cover.hide();
}

function unlock_window() {
    unlock_scroll_position();
    remove_cover();
}

$(document).ready(function() {
    var cookie_consent_screen = $('.cookie-consent-container')

    if (cookie_consent_screen.length) {
        lock_scroll_position()
    }
});
