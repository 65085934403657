/*
 * Amara, universalsubtitles.org
 *
 * Copyright (C) 2016 Participatory Culture Foundation
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see
 * http://www.gnu.org/licenses/agpl-3.0.html.
 */

//
// dialogs.js -- Dialog code
//

var $ = require('jquery');
var _ = require('underscore');
var querystring = require('./querystring');
var bodyScrollLock = require('body-scroll-lock');

var currentModal = null;
var progressBarTemplate = _.template('<div class="progressBar teal"><div class="progressBar-progress" role="progressbar" style="width: <%- percent %>;"><span class="sr-only"><%- percentLabel %></span></div></div><p class="progressBar-label teal"><%- label %></p>');
var flatpickrCount = 0;

function makeProgressBar(progress, label) {
    var percent = (progress * 100) + '%';
    return $(progressBarTemplate({
        percent: percent,
        percentLabel: interpolate(gettext('%(percent)s complete'), {percent: percent}),
        label: label
    }));
}

$.behaviors('.modal-show', showModalButton);
$.behaviors('.modal-close', hideModalButton);
$.behaviors('.modal', modal);

function showModalButton(elt) {
    var elt = $(elt);
    var modal = $(elt.data('target'));
    elt.click(function(evt) {
        showModal(modal);
        evt.stopPropagation();
        evt.preventDefault();
    });
}

function showModal(modal) {
    // var firstInputfocus = document.getElementById("ok");
    var body = $('body');
    var replacingModal = false;
    modal = $(modal);


    if(currentModal) {
        if(modal.attr('id') && modal.attr('id') == currentModal.attr('id')) {
          replacingModal = true;
        }
        closeCurrentModal();
    }

    // keep track of any flatpickrs calendars created not by the modal
    flatpickrCount = $('.flatpickr-calendar').length

    // If we're adding the element to DOM, first remove any existing modals with the same ID
    if(!$.contains(document.documentElement, modal[0])) {
        var modalId = modal.attr('id');
        if(modalId) {
            $('#' + modalId).remove();
        }
    }

    // Ensure the element is in the DOM.  This simplies our code, otherwise things like $('#some-element-id') won't find elements in the dialog.
    if(!document.body.contains(modal[0])) {
        modal.appendTo(body);
    }
    function showModal() {
        modal.addClass('modal-visible');
        bodyScrollLock.disableBodyScroll(modal);
        // after displaying the dialog, focus an element inside it for better keyboard nav; excludes calendar and navbar video search input
        elems = document.querySelector('form input:not([type=hidden], .datetime-input, .primarynav-video-search), form select, textarea');
        if (elems != null) {
            elems.focus()
        }
    }

    if(replacingModal) {
      $('<div class="modal-backdrop">').appendTo(body).show();
      showModal();
    } else {
      $('<div class="modal-backdrop">').appendTo(body).fadeIn(400, showModal);
    }

    if($('.modal-close', modal).length == 0) {
        setupImplicitClose(modal);
    }

    currentModal = modal;
    return modal;
}

// Function to check if the dialog with id 'add-videos-dialog' exists in the DOM
function isDialogDisplayed() {
    return !!document.getElementById('add-videos-dialog');
}

// Event listener for keyup events
document.addEventListener('keyup', function (e) {
    const dialogDisplayed = isDialogDisplayed();

    // If the Escape key is pressed and the dialog is displayed, prevent modal closure
    if (e.key === 'Escape' && dialogDisplayed) {
        e.stopPropagation(); // Stop propagation to prevent other modals from closing
        e.preventDefault(); // Prevent the default Escape key behavior
        return;
    }

    // If the Escape key is pressed and the dialog is not displayed, close the modal
    if (e.key === 'Escape' && !dialogDisplayed) {
        closeCurrentModal();
    }
});

function setupImplicitClose(modal) {
    // Add a close button
    var closeButton = $('<button class="modal-closeButton" aria-label="Close modal"><span aria-hidden="true" class="icon icon-close"></span></button>');
    closeButton.appendTo(modal).on('click', onCloseClick);

    // Clicks outside it will close the modal (except in dropdown menus, which get moved outside when they're opened)
    $(document).on('click.modal', function(evt) {
        if($(evt.target).closest(modal).length == 0
          && $(evt.target).closest('.dropdownMenu').length == 0) {
            onCloseClick(evt);
        }
    });
}

function hideModalButton(elt) {
    $(elt).click(onCloseClick);
}

function onCloseClick(evt) {
    closeCurrentModal();
    evt.stopPropagation();
    evt.preventDefault();
}

function closeCurrentModal() {
    if(currentModal) {
        var modal = currentModal;
        bodyScrollLock.enableBodyScroll(modal);
        modal.removeClass("modal-visible");
        while($('.flatpickr-calendar').length !== flatpickrCount) {
            $('.flatpickr-calendar').last().remove()
        }
        currentModal = null;
        $('.modal-backdrop').remove();
        $(document).off('click.modal');
        modal.trigger('model-closed');
        if(modal.hasClass('removeOnClose')) {
            modal.remove();
        }
    }
}

function modal(elt) {
    var elt = $(elt);

    if(elt.hasClass('shown')) {
        showModal(elt);
    }

    if(elt.closest('.ajaxDialog').length > 0) {
        showModal(elt);
        elt.on('model-closed', function(e) {
            // Remove dialog-related query parameters, this way if the user reloads after closing the modal, it doesn't appear again
            if(elt.data('queryParams')) {
              var paramsToRemove = elt.data('queryParams').split(':');
            } else {
              var paramsToRemove = [];
            }

            var query = querystring.parse();
            for(key in query) {
              if(paramsToRemove.indexOf(key) != -1 || key == 'dialog' || key.startsWith('dialog-')) {
                delete query[key];
              }
            }
            var url = '?' + querystring.format(query);
            history.replaceState(url, "", url);
        });
    }

}


module.exports = {
    // show/replace the our modal dialog
    showModal: showModal,
    closeCurrentModal: closeCurrentModal,
    // Show a progress bar on the current modal dialog
    showModalProgress: function(progress, label) {
        if(!currentModal) {
            console.log("showModalProgress(), no current modal");
            return;
        }
        var footer = $('.modal-footer', currentModal);
        footer.empty().append(makeProgressBar(progress, label));
    }
};
