/*
 * Amara, universalsubtitles.org
 *
 * Copyright (C) 2018 Participatory Culture Foundation
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see
 * http://www.gnu.org/licenses/agpl-3.0.html.
 */

var $ = require('jquery');
var filters = require('./filters');
var keyCodes = require('./keyCodes');

$.behaviors('.contentHeader-searchBar', contentHeaderSearchBar);

function contentHeaderSearchBar(input) {
    var input = $(input);
    input.on('keypress', function(evt) {
        if(evt.which == keyCodes.enter) {
            var value = input.val()
            var rv = input.triggerHandler('activate', value);
            if(rv !== false) {
                filters.add(input.attr('name'), value);
            }
            evt.preventDefault();
        }
    });
}

function tog(v) {
    return v ? "addClass" : "removeClass";
}
$(document).on("input", ".clearable", function() {
    $(this).autofocus = true;
    $(this)[tog(this.value)]("x");
}).on("mousemove", ".x", function(e) {
    $(this)[tog(this.offsetWidth - 76 < e.clientX - this.getBoundingClientRect().left)]("onX");
}).on("touchstart click", ".onX", function(ev) {
    ev.preventDefault();
    $(this).removeClass("x onX").val("").change();

    // Get the current query parameters
    const currentUrl = new URL(window.location.href);
    const params = currentUrl.searchParams;

    // Check if the URL has a query string
    if (currentUrl.search !== "") {
        // Remove the specified parameters from team workspace query
        params.delete('q');
        params.delete('q2');
        params.delete('q_manage');
        // From team workspace query
        params.delete('q_contribute');
        params.delete('q_join');

        // Construct the new URL with updated query parameters
        const newUrl = currentUrl.origin + currentUrl.pathname + currentUrl.search;

        // Redirect to the new URL
        window.location.href = newUrl;
    }

    $(this).autofocus = true;
});
