window.$ = window.jQuery = require('jquery');

require('bootstrap-sass/assets/javascripts/bootstrap/carousel');
require('venobox');
require('waypoints');
require('inview');
require('select2')(window, $);
require('./animations');
require('../shared/third-party/behaviors');
require('../shared/formValidation');
require('../shared/selectRange');
require('../shared/copyText');
require('../shared/announcements');
require('../shared/messages');
require('../shared/select/main');
require('../shared/fileUpload');
require('../shared/compoundField');
require('../shared/ajax');
require('../shared/formSubmit');
require('../shared/languageSwitcher');
require('../shared/tabs');
require('../shared/dropdown');
require('../shared/listView');
require('../shared/checkAll');
require('../shared/contentHeader');
require('../shared/menuToggle');
require('../shared/accordion');
require('../shared/expandButton');
require('../shared/timezones');
require('../shared/pricingAccordions');
require('../shared/cookieConsent');
require('../shared/multiStepPane');
require('../shared/pagination');
require('./aodMarketing');

var slider = require('./slider');
var message = require('./message');
var testimonials = require('./testimonials');
var consolidateOffset = 250;
var pageOffset = 100;

$(window).scroll(function(){
  if ($(window).scrollTop() >= consolidateOffset) {
    $('body').addClass('consolidate-header');
   }
   else {
    $('body').removeClass('consolidate-header');
   }
});

$(document).ready(function() {
  // Instantiate venobox
  $('.venobox').venobox();

  // Animate sliders
  slider.animate();

  // Messages
  if(message.check) {
    // Show the message
    message.show();
  } else {
    // Remove message from DOM?
  }

  // Display random testimonials
  $('.testimonials').each(function() {
    var quotes = $(this).find('blockquote');
    testimonials.showRandom(quotes);
  });


  // Select all links with hashes
  $('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .on('click', function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
      location.hostname === this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top - pageOffset
        }, 1000, function() {
          // Focus the element after the animation
          $(target).focus();
        });
      }
    }
  });
  //check for hash on page load
  if (window.location.hash) {
    resolveHash();
  }

});

function resolveHash() {
  var hash = '#' + window.location.hash.substring(1);

  if ($(hash).hasClass('tab-pane') === true) {
    $('a[href*="' + hash + '"]').tab('show');
    // TODO - This should be modified to be more exact. Currently not in use though
    $('html, body').animate({ scrollTop: $(hash).offset().top - pageOffset + 80 }, 1000);
  }
  else {
    $('html, body').animate({ scrollTop: $(hash).offset().top - pageOffset}, 1000);
  }
}


// Simple display animation for marketing landing pages (AV and AOD)
var $animated = $('.animated');
var $window = $(window);

function check_in_view() {
  var w_height = $window.height();
  var w_top = $window.scrollTop();
  var w_bottom = (w_height + w_top);

  $.each($animated, function(){
  var e_height = $(this).outerHeight();
  var e_top = $(this).offset().top;
  var e_bottom = (e_height + e_top);

    if((e_bottom >= w_top) && (e_top <= w_bottom)){
      $(this).addClass('in-view');
    }else{
      $(this).removeClass('in-view');
    }

  });
}
  // event handler
function copyToClipboard(e) {
  // find target element
  var t = e.target;
  var c = t.dataset.copytarget;
  var inp = (c ? document.querySelector(c) : null);
  console.log(inp);
  // check if input element exist and if it's selectable
  if (inp && inp.select) {
    // select text
    inp.select();
    try {
      // copy text
      document.execCommand('copy');
      inp.blur();

      // copied animation
      t.classList.add('copied');
      setTimeout(function() {
        t.classList.remove('copied');
      }, 1500);
    } catch (err) {
      //fallback in case exexCommand doesnt work
      alert('please press Ctrl/Cmd+C to copy');
    }}}
// AMPLIFYING VOICES LANDING

$(function(){
  if($('body').is('.amplifying-voices_view')){
    // Onscroll floating animation for figures images and percents in Amplifying Voices landing page
    // let the browser know you are scrolling and it will trigger the event
    $window.on('scroll', check_in_view);
    $window.trigger('scroll');
    $('.container-figures').height($(window).height());
    // Variables
    var viewport = $(window),
      root = $('html'),
      maxScroll;
    // Bind events to window
    viewport.on({
      scroll: function() {
        // Grab scroll position
        var scrolled = viewport.scrollTop();
        /**
         * Calculate our factor, setting it as the root `font-size`.
         *
         * Our factor is calculated by multiplying the ratio of the page scrolled by our base factor. The higher the base factor, the larger the parallax effect.
         */
        root.css({
          fontSize: (scrolled / maxScroll) * 25
        });
      },
      resize: function() {
        // Calculate the maximum scroll position
        maxScroll = root.height() - viewport.height();
      }
    }).trigger('resize');

    // Zoom into images onscroll
    const zoomElement2 = document.querySelector(".zoom2");
    const zoomElement3 = document.querySelector(".zoom3");
    let zoom = 1;
    const ZOOM_SPEED = 0.00015;

    document.addEventListener("wheel", function(e) {

        if(e.deltaY > 0){
            zoomElement2.style.transform = `scale(${zoom += ZOOM_SPEED})`;
            zoomElement3.style.transform = `scale(${zoom += ZOOM_SPEED})`;
        }else{
            zoomElement2.style.transform = `scale(${zoom -= ZOOM_SPEED})`;
            zoomElement3.style.transform = `scale(${zoom -= ZOOM_SPEED})`;
          }

    });
    //Video speed
    document.querySelector('video').playbackRate = 0.75;

    // functionality to copy Amplifying Voices email to clipboard
    // trigger copy event on click
    $('#copy').on('click', function(event) {
      console.log(event);
      copyToClipboard(event);
    });


    //AV landing page : automatic scroll to form and name input focus when CTA in join section is clicked.
    const volunteerBtn = document.getElementById('id_role_0');
    const contentBtn = document.getElementById('id_role_1');
    const communityBtn = document.getElementById('id_role_2');
    const fundingBtn = document.getElementById('id_role_3');
    const testFocus = document.getElementById('name');
    //Added a media query so the keyboard is not triggered on mobile phone when focusing on name input
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    $('#join-av a.content_partner').on('click', function() {
      $('html, body').animate({
        scrollTop: $("#av-form").offset().top
      }, 1000);
      volunteerBtn.checked = false;
      contentBtn.checked = true;
      communityBtn.checked = false;
      fundingBtn.checked = false;
      if (mediaQuery.matches) {
        testFocus.focus();
      }
    })
    $('#join-av a.community_partner').on('click', function() {
      $('html, body').animate({
        scrollTop: $("#av-form").offset().top
      }, 1000);
      volunteerBtn.checked = false;
      contentBtn.checked = false;
      communityBtn.checked = true;
      fundingBtn.checked = false;
      if (mediaQuery.matches) {
        testFocus.focus();
      }
    });

    $('#join-av a.funding_partner').on('click', function() {
      $('html, body').animate({
        scrollTop: $("#av-form").offset().top
      }, 1000);
      volunteerBtn.checked = false;
      contentBtn.checked = false;
      communityBtn.checked = false;
      fundingBtn.checked = true;
      if (mediaQuery.matches) {
        testFocus.focus();
      }
    });

    //Amplifying voices Hide/Show sticky footer
    const body = document.body;
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    let lastScroll = 0;
    window.addEventListener("scroll", () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 0) {
        body.classList.remove(scrollUp);
        return;
      }

      if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
      } else if (
        currentScroll < lastScroll &&
        body.classList.contains(scrollDown)
      ) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
      }
      lastScroll = currentScroll;
    });
    // remove sticky-cta if clicked
    $('#get-involved').on('click', function() {
      $('.sticky-cta').addClass('hidden');
    });
  }
});

$( document ).ready(function() {
  $('#dropdown-menu-button').click(function(e) {
    e.preventDefault();
    $('#dropdown-menu-button .caret-dropdown').toggleClass('active');
  });
});


// Hide Header on on scroll down
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('#page-header').outerHeight();

$(window).scroll(function(){
  didScroll = true;
});

setInterval(function() {
  if (didScroll) {
      hasScrolled();
      didScroll = false;
  }
}, 250);

function hasScrolled() {
  var st = $(this).scrollTop();

  // Make sure they scroll more than delta
  if(Math.abs(lastScrollTop - st) <= delta)
      return;

  // If they scrolled down and are past the navbar, add class .nav-up.
  // This is necessary so you never see what is "behind" the navbar.
  if (st > lastScrollTop && st > navbarHeight){
      // Scroll Down
      $('#page-header ').removeClass('nav-down').addClass('nav-up');
  } else {
      // Scroll Up
      if(st + $(window).height() < $(document).height()) {
          $('#page-header').removeClass('nav-up').addClass('nav-down');
      }
  }

  lastScrollTop = st;
}

//hamburder icon animation
$(document).ready(function(){
  $('#simply-burger').click(function(){
    //On click, toggle on 'this' (#simply-burger) the class open
    $(this).toggleClass('open');
  });
});
