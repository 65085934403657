// Copyright 2019 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery')

$.behaviors('form', function(form) {
    form = $(form);
    form.prop('noValidate', true); // we're going to handle validation ourselves
});

// Handle the form submit events.  Use a capture phase event handler so that
// this handler gets called before others.
function submitHandler(evt) {
    var form = $(evt.target);
    var invalidInputs = findInvalidInputs(form);
    if(invalidInputs.length > 0) {
        evt.preventDefault();
        evt.stopPropagation();
        $.each(invalidInputs, function() { addClientSideFieldError($(this));});
        invalidInputs.first().closest('.form-group').scrollTo();
        form.trigger('validation-failed');
    } else {
        form.find('.client-validation-error').each(function() {
            removeClientSideFieldError($(this));
        });
    }
}
document.addEventListener('submit', submitHandler, {capture: true});

function findInvalidInputs(form) {
    // Jquery doesn't support :invalid selector, so we need to implement this ourselves
    // (See https://github.com/jquery/sizzle/pull/413)

    var rawForm = form.get(0);
    var invalid;
    if(rawForm.querySelectorAll) {
        invalid = $(rawForm.querySelectorAll(':invalid')).filter(':input');
    } else {
        invalid = $([]);
    }
    // For radio buttons, we need to do an extra pass because none of the
    // individual buttons are required, but at least 1 must be selected.
    invalid = invalid.add(form.find('.radio-required').not(':has(:checked)'));
    return invalid;
}

function addClientSideFieldError(input) {
    var formGroup = input.closest('.form-group');
    if(!input.hasClass('client-validation-error')) {
        formGroup.data({
            hadError: input.hasClass('has-error'),
            oldHelpBlock: formGroup.find('.helpBlock').remove()
        });
        formGroup.addClass('has-error client-validation-error');
        formGroup.append($('<div class="helpBlock"></div>').text(calcClientSideValidationError(input)));
    } else {
        formGroup.find('.helpBlock').text(calcClientSideValidationError(input));
    }
}

function removeClientSideFieldError(formGroup) {
    var formGroupData = formGroup.data();
    if(!formGroupData.hadError) {
        formGroup.removeClass('has-error');
    }
    formGroup.find('.helpBlock').remove();
    if(formGroupData.oldHelpBlock) {
        formGroup.append(formGroupData.oldHelpBlock);
    }
    formGroup.removeClass('client-validation-error');
    formGroup.removeData(['hadError', 'oldHelpBlock']);
}

function calcClientSideValidationError(input) {
    var input = input.get(0);
    var targetEle = this.hash;
    var $targetEle = $('.has-error');
    if(input.classList.contains('form-group') || input.validity.valueMissing) {
        $('#info-error').removeClass('hide');
        $('#info-error').addClass('display');
        $('html, body').stop().animate({
            'scrollTop': $targetEle.offset().top-100
        }, 800, 'swing', function () {
            window.location.hash = targetEle;
        });
        return _('This field is required.');
    } else {
        $('#info-error').addClass('hide');
        return input.validationMessage;
    }


}
