/*
 * Amara, universalsubtitles.org
 *
 * Copyright (C) 2016 Participatory Culture Foundation
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see
 * http://www.gnu.org/licenses/agpl-3.0.html.
 */

//
// ajax.js -- Amara AJAX functionality

var $ = require('jquery');
var _ = require('underscore');
var querystring = require('./querystring');
var dialogs = require('./dialogs');
var cookies = require('browser-cookies');

$.behaviors('.ajaxForm', ajaxForm);
$.behaviors('.ajaxSubmit', ajaxSubmit);
$.behaviors('.ajaxLink', ajaxLink);
$.behaviors('body.ajaxRefresh', ajaxRefresh);

var responseProcessors = {};

function processAjaxResponse(responseData) {
    // Handles processing our AJAX responses generated by the ui.ajax.AJAXResponseRender class.
    _.each(responseData, function(change) {
        if(change[0] in responseProcessors) {
          var processFunc = responseProcessors[change[0]]
          processFunc(change);
          return;
        }
        switch(change[0]) {
            case 'replace':
                var container = $(change[1]);
                var content = $(change[2]);
                container.empty().append(content);
                container.updateBehaviors();
                break;

            case 'replaceText':
                var container = $(change[1]);
                container.empty().text(change[2]);
                container.updateBehaviors();
                break;
            
            case 'append':
                var container = $(change[1]);
                container.append(change[2]);
                container.updateBehaviors();
                break;

            case 'fadeIn':
                var container = $(change[1]);
                var content = $(change[2]);
                container.fadeOut(200, function() {
                  container.empty().append(content).updateBehaviors().fadeIn(200);
                });
                break;

            case 'remove':
                $(change[1]).remove();
                break;

            case 'setHidden':
                $(change[1]).toggleClass('hidden', $(change[2]));
                break;
            
            case 'show':
                $(change[1]).show();
                break;
            
            case 'hide':
                $(change[1]).hide();
                break;
            
            case 'showModal':
                dialogs.showModal(change[1]).updateBehaviors();
                break;

            case 'hideModal':
                dialogs.closeCurrentModal();
                break;

            case 'showModalProgress':
                dialogs.showModalProgress(change[1], change[2]);
                break;

            case 'addAccordionSection':
                var elt = $(change[1]);
                elt.accordion('addSection', change[2], $(change[3]));
                break;

            case 'autoScrollUp':
                $(window).scrollTop(0);
                break;

            case 'performRequest':
                setTimeout(function() {
                    $.ajax(change[1], {
                        success: processAjaxResponse
                    });
                }, change[2]);
                break;

            case 'clearForm':
                $(change[1]).clearForm();
                break;

            case 'reloadPage':
                $(window).on('beforeunload', scrollAfterReload);
                // Force a GET request on reload
                window.location.reload(true);
                break;

            case 'redirect':
                window.location = change[1];
                break;

            default:
                console.warn('Error handling ajax update: ', change);
        }
    });
}

function scrollAfterReload() {
    $(window).scrollTop(0);
    $(window).off('beforeunload', scrollAfterReload);
}

function ajaxForm(form) {
    var submitting = false;
    var sawSecondSubmit = false;
    var inPeriodicSubmit = false;
    form = $(form);
    form.add('button', form).ajaxForm({
        beforeSubmit: function(data) {
            if(submitting) {
                sawSecondSubmit = true;
                return false;
            }
            submitting = true;
            lastSerialize = form.formSerialize();
            if(form.hasClass('updateLocation')) {
                updateLocation(false);
            }
            if(form.hasClass('copyQuery')) {
                _.each(querystring.parse(), function(value, name) {
                    data.push({ name: name, value: value });
                });
            }
            if(form.hasClass('disableInputsOnSubmit')) {
                $(':input', form).prop('disabled', true);
            }
        },
        complete: function(xhr, status) {
            submitting = false;
            if(sawSecondSubmit) {
                sawSecondSubmit = false;
                form.submit();
            }
            if(inPeriodicSubmit) {
                inPeriodicSubmit = false;
                if(status == 'success') {
                    schedulePeriodicSubmit();
                }
            }
        },
        success: function(data) {
          form.trigger('ajax-submit-success');
          form.trigger('ajax-submit-complete');
          processAjaxResponse(data);
        },
        error: function() {
          form.trigger('ajax-submit-error');
          form.trigger('ajax-submit-complete');
            if(!inPeriodicSubmit) {
                alert(gettext('Error submitting form'));
                dialogs.closeCurrentModal();
            }
        }
    });

    if(form.hasClass('updateOnChange')) {
        $(':input', form).change(submitIfChanged);
        $('input[type=text].updateOnKeyUp', form).keyup(submitIfChanged);
    }

    function schedulePeriodicSubmit() {
        var delay = form.data('period') * 1000;
        setTimeout(function() {
            inPeriodicSubmit = true;
            form.submit();
        }, delay);
    }

    if(form.hasClass('updatePeriodically') &&
            $.isNumeric(form.data('period'))) {
        schedulePeriodicSubmit();
    }

    var lastSerialize = form.formSerialize();
    function formHasChanged() {
        return form.formSerialize() != lastSerialize;
    }
    function submitIfChanged() {
        if(formHasChanged()) {
            if(!form.hasClass('doNotUpdateLocation')) {
                updateLocation(true);
            }
            form.submit();
        }
    }
    function updateLocation(resetPage) {
        if(!formHasChanged()) {
            return;
        }
        var params = querystring.parse();
        var new_url_params = calc_url_params(params, form);
        var url = window.location.protocol + "//" + window.location.host +
            window.location.pathname + '?' + new_url_params

        if(!resetPage && params.page) {
            url += '&page=' + params.page;
        }
        if (url != history.state) {
            history.pushState(url, "", url);
        }
    }

    /*
     *  the url parameters are calculated as the new GET parameters from the submitted form
     *  and the remaining parameters from the query string (specifically for handling pages with two filters)
     */
    function calc_url_params(url_params, form) {
        var form_params = form.formToArray()

        var new_url_params = {}
        $.each(form_params, function(index, param) {
            new_url_params[param.name] = param.value
        })

        $.each(url_params, function(key, value) {
            if (key in new_url_params == false) {
                new_url_params[key] = value
            }
        })

        return $.param(new_url_params)
    }

}

function ajaxLink(link) {
    var link = $(link);
    var inProgress = false;
    var linkContents = null;

    var ajaxParams = {
        beforeSend: function() {
            if(inProgress) {
                return false;
            }
            inProgress = true;
            if(link.hasClass('loadingIcon')) {
                link.css({
                    'width': link.css('width'),
                });
                linkContents = link.contents();
                linkContents.detach();
                link.empty().append($('<i class="icon icon-loading"></i>'));
            }
        },
        complete: function() {
            inProgress = false;
            if(link.hasClass('loadingIcon')) {
                link.removeAttr('style');
                link.empty().append(linkContents);
            }
        },
        success: processAjaxResponse,
    };

    link.click(function() {
        var url = link.data('href') || link.attr('href');
        $.ajax(url, ajaxParams);
        return (link.attr('data-nav') === "true");
    });
}

function ajaxSubmit(button) {
    button = $(button);


    button.on('click', function(evt) {
        var form = button.closest('form');
        // use setTimeout to trigger ajax-submit after the click handlers
        setTimeout(function() { ajaxSubmitForm(form) }, 0);
        evt.preventDefault();
    });

}
function ajaxSubmitForm(form) {
    form.trigger('ajax-submit');
    form.ajaxSubmit({
        success: function() {
            form.trigger('ajax-submit-success');
            form.trigger('ajax-submit-complete');
        },
        error: function() {
            form.trigger('ajax-submit-error');
            form.trigger('ajax-submit-complete');
        }
    });
}

// Call an ajax function to update the page
function update(url, options) {
    if(options === undefined) {
        options = {};
    }
    if(options.pushState) {
        history.pushState(url, "", url);
    } else if(!options.keepState) {
        history.replaceState(url, "", url);
    }
    return $.ajax(url, {
        success: processAjaxResponse,
    });
}

// POST data, then update using the ajax processing code
function post(url, data, options) {
    if(options === undefined) {
        options = {};
    }
    var ajaxParams = {
        url: url,
        method: 'POST',
        data: data,
        headers: { "X-CSRFToken": cookies.get('csrftoken')}
    };
    if(!options.raw) {
        ajaxParams.success = processAjaxResponse;
    }
    return $.ajax(ajaxParams);
}

function ajaxRefresh(body) {
    body = $(body);
    var seconds = body.data('seconds');
    var query = body.data('query');
    var once = body.hasClass('once');
    var timeout = null;

    if(seconds === undefined) {
        return;
    }

    function calcUrl() {
        if(query) {
            if(query[0] != '?') {
              query = '?' + query;
            }
            return query;
        } else if (window.location.search) {
            return window.location.search;
        } else {
            return '?';
        }
    }

    function scheduleRefresh() {
        if(timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        timeout = setTimeout(doRefresh, seconds * 1000);
    }

    function doRefresh() {
        var url = calcUrl();

        $.ajax(url, {
            success: function(responseData) {
                // call processAjaxResponse unless the filters have changed,
                // since then we would be loading an old version of the page.
                // To check this, call calcUrl() again and make sure it returns
                // the same value as before
                if(calcUrl() == url) {
                    processAjaxResponse(responseData);
                }
            },
            complete: function() {
              if(!once) {
                scheduleRefresh();
              }
            },
        });
    }


    window.addEventListener('pageshow', function(evt) {
        if(evt.persisted === true) {
            // user navigated forward/back to a cached page.  Reload now rather than waiting for the timer to expire
            doRefresh();
        }
    });

    scheduleRefresh();
}

module.exports = {
    update: update,
    post: post,
    responseProcessors: responseProcessors
};
