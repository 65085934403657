var $ = require('jquery');

$.behaviors('.pricingAccordionTrigger', function(selector) {
    var trigger = $(selector);

    trigger.on('click', function() {
        if (trigger.hasClass('hover')) {
            return;
        }

        $('.pricingAccordionTrigger.hover').removeClass('hover');
        trigger.addClass('hover');
        target = trigger.data('target');
        $('.pricingAccordion').hide()
        $('#' + target).fadeIn();
    });
});
