document.addEventListener("DOMContentLoaded", function() {
    var items = document.querySelectorAll(".list-wrapper .list-item");
    var numItems = items.length;
    var perPage = 3;
    var currentPage = 1;

    function showPage(page) {
        var startIndex = (page - 1) * perPage;
        var endIndex = startIndex + perPage;

        items.forEach(function(item, index) {
            if (index >= startIndex && index < endIndex) {
                item.style.display = "block";
            } else {
                item.style.display = "none";
            }
        });
    }

    function updatePagination() {
        var totalPages = Math.ceil(numItems / perPage);
        var paginationContainer = document.getElementById("pagination-container");

        paginationContainer.innerHTML = '<ul class="pager"></ul>';
        var pagerUl = paginationContainer.querySelector('.pager');
        for (var i = 1; i <= totalPages; i++) {
            var pageLink = document.createElement("li");
            var pageLinkAnchor = document.createElement("a");
            pageLinkAnchor.href = "#";
            pageLinkAnchor.textContent = i;

            if (i === currentPage) {
                pageLink.classList.add("active");
            }

            pageLinkAnchor.addEventListener("click", function(event) {
                event.preventDefault();
                currentPage = parseInt(this.textContent);
                showPage(currentPage);

                // Update active class on pagination links
                var paginationLinks = pagerUl.getElementsByTagName("li");
                for (var j = 0; j < paginationLinks.length; j++) {
                    paginationLinks[j].classList.remove("active");
                }
                this.parentNode.classList.add("active");
            });

            pageLink.appendChild(pageLinkAnchor);
            pagerUl.appendChild(pageLink);
        }
    }

    // Initial display
    showPage(currentPage);
    updatePagination();
});
