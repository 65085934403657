/*
 * Amara, universalsubtitles.org
 *
 * Copyright (C) 2018 Participatory Culture Foundation
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see
 * http://www.gnu.org/licenses/agpl-3.0.html.
 */


$.behaviors('.checkAll', checkAll);

function checkAll(checkbox) {
    checkbox = $(checkbox);
    var name = checkbox.data('name');
    var container = checkbox.data('container');
    if(container) {
        container = $(container);
    } else {
        // try to find the selectionGroup for the checkbox
        container = checkbox.closest('.selectionGroup');
        // fall back on the entire document
        if(container.length == 0) {
            container = $(document);
        }
    }
    var inputs = $('input[type=checkbox][name=' + name + ']', container);

    checkbox.change(function() {
        inputs.prop('checked', checkbox.prop('checked'));
        inputs.trigger('change');
    });
    inputs.change(function() {
        var allChecked = inputs.filter(':checked').length == inputs.length;
        checkbox.prop('checked', allChecked);
    });
}
