/* Amara, amara.org
 *
 * Copyright (C) 2019 Participatory Culture Foundation
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see
 * http://www.gnu.org/licenses/agpl-3.0.html.
 */

var $ = require('jquery');
var flatpickr = require('flatpickr')
var confirmDatePlugin = require('flatpickr/dist/plugins/confirmDate/confirmDate')
var jstz = require('jstz');

$.behaviors('.datetime-display', function(element) {
    var element = $(element);
    if(element.hasClass('short')) {
        var options = { month: 'short',
                        day: 'numeric',
                        hour:'numeric',
                        minute: 'numeric' };
    } else if(element.hasClass('date')) {
        var options = { year: 'numeric',
                        month: 'short',
                        day: 'numeric' };
    } else {
        var options = { year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric' };
    }
    element.html(new Date(element.data('datetime')).toLocaleString(navigator.language, options))
});

$.behaviors('.datetime-input', function(element) {
    var element = $(element)

    function addButtons(instance) {
        var $cal = $(instance.calendarContainer);
        var button_width = '50%';

        if (element.data('multiple')) {
            button_width = '33%';
            if ($cal.find('.datetime-input-clear').length < 1) {
                var clear_button = $('<div class="datetime-input-clear button cta-reverse">' + _('Clear') + '</div>')
                clear_button.css('width', '34%')
                $cal.append(clear_button);
                $cal.find('.datetime-input-clear').on('click', function() {
                    instance.clear();
                    instance.input.value = '$nochange';
                    instance.close();

                    datetime_display = $(instance.input).siblings('[readonly=readonly]');
                    datetime_display.addClass('datetime-input-placeholder');
                    datetime_display.val(_('No change'));
                });
            }
        }

        if ($cal.find('.datetime-input-unset').length < 1) {
            var unset_button = $('<div class="datetime-input-unset button secondary">' + _('Unset') + '</div>')
            unset_button.css('width', button_width)
            $cal.append(unset_button);
            $cal.find('.datetime-input-unset').on('click', function() {
                instance.clear();
                instance.close();

                datetime_display = $(instance.input).siblings('[readonly=readonly]');
                datetime_display.removeClass('datetime-input-placeholder')
                datetime_display.val(_('Unset'));
            });
        }
        if ($cal.find('.datetime-input-close').length < 1) {
            var set_button = $('<div class="datetime-input-close button cta">' + _('Set') + '</div>')
            set_button.css('width', button_width)
            $cal.append(set_button);
            $cal.find('.datetime-input-close').on('click', function() {
                instance.close();

                datetime_display = $(instance.input).siblings('[readonly=readonly]');
                datetime_display.removeClass('datetime-input-placeholder')
            });
        }
    }

    var config = {
        enableTime: true,
        altInput: true,
        /** the 'static' option is for snapping the calendar picker to its input field. for now we disable this since
        it's kinda problematic for modals with limited space (e.g. the edit outgoing requests modal
        barely has any space for the calendar) -- setting static: true does not overflow the calendar picker
        beyond the modal content space and I'm not even sure it's possible without doing something super hacky **/
        // static: true,
        dateFormat: "Z",
        onReady: function(dateObj, dateStr, instance) {
            this.showTimeInput = true;
            $(this.altInput).removeClass('form-control');
            addButtons(instance);
        }
    }

    if (element.data('initial-datetime')) {
        d = flatpickr.parseDate(element.data('initial-datetime'), "Z")
        config.defaultDate = d
    }

    if (element.data('default-time')) {
        var time = element.data('default-time')

        config.defaultHour = time[0]
        config.defaultMinute = time[1]
        config.defaultSecond = time[2]
    }


    var instance = element.flatpickr(config);

    if (element.data('multiple')) {
        datetime_display = element.siblings('[readonly=readonly]')
        datetime_display.addClass('datetime-input-placeholder');
        datetime_display.val(_('No change'));

        instance.input.value = '$nochange';
    }
})

$.behaviors('.datetime-currentTimezone', function(element) {
    function updateDisplay() {
        var timezone = jstz.determine().name().replace(/_/g, ' ');
        var now = new Date();
        var currentTimeDisplay = now.toLocaleString(navigator.language, {
            hour: '2-digit', minute: '2-digit'
        });
        $(element).empty()
            .append('<span class="fa fa-clock"></span>')
            .append(document.createTextNode(' ' + currentTimeDisplay + ' (' + timezone + ')'));
        scheduleUpdateDisplay();
    }
    function scheduleUpdateDisplay() {
        var now = new Date();
        var msUntilNextMinute = 60000 - (now.getTime() % 60000);
        // Add 0.1 seconds padding, to make sure that the we don't invoke updateDisplay() before the switchover
        msUntilNextMinute += 100;
        setTimeout(updateDisplay, msUntilNextMinute);
    }

    updateDisplay();
});
