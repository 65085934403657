/* Amara, universalsubtitles.org
 *
 * Copyright (C) 2017 Participatory Culture Foundation
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see
 * http://www.gnu.org/licenses/agpl-3.0.html.
 */

var $ = require('jquery');
var cookies = require('browser-cookies');
var dropdown = require('./dropdown');

$.behaviors('.tabs > a, .tabSwitcher', function(elt) {
    $(elt).on('click', onTabClick);
});

function onTabClick(evt) {
  var tabSwitcher = $(this);
  var target = findTabTarget(tabSwitcher);
  if(target) {
    var rv = tabSwitcher.triggerHandler('tab-activate', target);
    if(rv !== false) {
      activateTab(tabSwitcher, target);
      return false;
    }
  }
}

function findTabTarget(elt) {
  var targetId = elt.attr('href') || elt.data('target');
  if(targetId) {
    var target = $(targetId);
    if(target.length > 0) {
      return target;
    }
  }
  return null;
}

$.behaviors('.tabs', handleTabOverflow);


function handleTabOverflow(container) {
  container = $(container);
  var menu = null;
  var menuButtonContainer = null;
  var overflowTabs = [];

  function checkOverflow() {
    var overflow = calcOverflow();
    if(overflow > 0) {
      ensureMenu();
      removeOverflowTabs(overflow);
    } else {
      addBackOverflowTabs(-overflow);
      if(overflowTabs.length == 0) {
        ensureMenuDestroyed();
      }
    }
  }

  function ensureMenu() {
    if(!menu) {
      var menuId = 'tab-overflow-' + Math.random().toString(36).substring(4);
      menu = dropdown.create(menuId, [], {noIcon: true, dropLeft: true});
      menu.on('link-activate', function(evt, target) {
        activateTab(evt.link, target);
      });
    }
    if(!menuButtonContainer) {
      var menuButton = $('<button class="tabs-menuButton dropdownMenu-button"><span class="fa fa-ellipsis-v"></span></button>');
      menuButton.data('menu', menu);
      menuButtonContainer = $('<div class="tabs-menuButtonContainer">').append(menuButton);
      menuButtonContainer.updateBehaviors();
      container.append(menuButtonContainer);
    }
  }
  function ensureMenuDestroyed() {
    if(menuButtonContainer) {
      menuButtonContainer.remove()
      menuButtonContainer = null;
    }
  }

  function removeOverflowTabs(overflow) {
    var allTabs = container.children('a').toArray();
    while(overflow > 0 && allTabs.length > 0) {
      var tab = $(allTabs.pop());
      var tabWidth = tab.outerWidth(true);
      tab.remove();
      makeMenuItemForTab(tab);
      overflowTabs.push({
        elt: tab,
        width: tabWidth
      });
      overflow -= tabWidth;
    }
  }

  function makeMenuItemForTab(tab) {
    var props = {
      text: tab.text()
    };
    var target = findTabTarget(tab);
    if(target) {
      // Handle tabs that show content on the page
      props.args = [target];
    } else {
      // Handle tabs that link to other pages
      props.href = tab.attr('href');
    }
    menu.dropdown('prependItem', props);
  }

  function addBackOverflowTabs(underflow) {
    while(overflowTabs.length > 0) {
      var lastTab = overflowTabs[overflowTabs.length - 1];
      if(lastTab.width < underflow) {
        container.append(lastTab.elt);
        underflow -= lastTab.width;
        overflowTabs.pop();
        menu.dropdown('removeItem', 0);
      } else {
        break;
      }
    }
  }

  function calcOverflow() {
    var lastTab = container.children('a').last();
    var tabRight = lastTab.offset().left - container.offset().left + lastTab.outerWidth(true);
    return tabRight - container.outerWidth();
  }


  if(!container.hasClass('vertical')) {
    $(window).on('resize', checkOverflow);
    checkOverflow();
  }
}

$.behaviors('.tabs[data-persist]', function(elt) {
    elt = $(elt)
    elt.children('a').on('tab-activate', function(evt, tab) {
        var name = elt.data('persist');
        var id = $(tab).attr('id');
        if(name && id) {
            cookies.set(name, id);
        }
    });

    var currentValue = cookies.get(elt.data('persist'));
    if(currentValue) {
        var activeTab = $('#' + currentValue);
        if(activeTab.length) {
            tabSwitcher = elt.children('[href="#' + currentValue + '"], [data-target="#' + currentValue + '"]');
            if(tabSwitcher.length == 1) {
                activateTab(tabSwitcher, activeTab);
            }
        }
    }
});

function activateTab(tabSwitcher, tab) {
    var oldTab = tab.siblings('.tab.active');
    tab.addClass('active');
    oldTab.removeClass('active');

    tabSwitcher.addClass('active');
    tabSwitcher.siblings('a.active').removeClass('active');

    moveSelection(oldTab, tab);
};

function moveSelection(oldTab, newTab) {
    var selection = {}; //

    $('input[name=selection]:checked', oldTab).each(function() {
        var cb = $(this);
        selection[cb.val()] = true;
        cb.prop('checked', false).change();
    });

    $('input[name=selection]', newTab).each(function() {
        var cb = $(this);
        var shouldBeChecked = (cb.val() in selection);
        if(shouldBeChecked != cb.prop('checked')) {
            cb.prop('checked', shouldBeChecked).change();
        }
    });
}

// Old style tabs -- remove this code once we switch over everything to use the new code

$.behaviors('.persistentTabs', function(elt) {
    var cookieName = $(elt).data('persistentTabCookie');
    if(!cookieName) {
        return;
    }
    try {
        var currentValue = cookies.get(cookieName);
        if(currentValue) {
            var activeTab = $(currentValue);
            if(activeTab) {
                var container = activeTab.closest('.tab-content');
                $('.tab-pane', container).removeClass('active');
                activeTab.addClass('active');
            }

            var activeLI = $('[href="' + currentValue + '"], [data-target="' + currentValue + '"]').parent('li');
            if(activeLI) {
                activeLI.siblings('li').removeClass('active')
                activeLI.addClass('active');
            }
        }
    }
    catch (error) {
        console.error(error);
    }
    $('.persistentTab', elt).click(function(evt) {
        var elt = $(this);
        var cookieValue = elt.data('persistentTabValue') || elt.attr('href') || elt.data('target');
        cookies.set(cookieName, cookieValue);
    });
});

