/*
 * Amara, universalsubtitles.org
 *
 * Copyright (C) 2016 Participatory Culture Foundation
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see
 * http://www.gnu.org/licenses/agpl-3.0.html.
 */

/*
 * select/main.js -- Top-level select code.
 */


var $ = require('jquery');
var _ = require('underscore');

var makeDataAdapter = require('./data');
var makeResultsAdapter = require('./results');
var Languages = require('./languages');

var s2require = $.fn.select2.amd.require;
var Utils = s2require('select2/utils');
var Dropdown = s2require('select2/dropdown');
var DropdownSearch = s2require('select2/dropdown/search');
var CloseOnSelect = s2require('select2/dropdown/closeOnSelect');
var AttachBody = s2require('select2/dropdown/attachBody');
var MinimumResultsForSearch = s2require('select2/dropdown/minimumResultsForSearch');
var EventRelay = s2require('select2/selection/eventRelay');
var Placeholder = s2require('select2/selection/placeholder');
var SingleSelection = s2require('select2/selection/single');
var MultipleSelection = s2require('select2/selection/multiple');

function initSelect(select) {
  select = $(select);

  var options = makeOptions(select);

  select.select2(options);
  focusInputAfterSelection(select, options);
  clearButtonHack(select, options);
  handleClear(select, options);

  addContainerClasses(select, options);
}

function reloadSelect(elt) {
    // Reloading data is tricky because select2 doesn't expect to have multiple
    // options with the same value, like we do with languages in both the
    // popular and all sections.  So destroy the select2, and rebuild it from
    // scratch.
    elt.select2('destroy');
    initSelect(elt);
}

function makeOptions(select) {
  var options = {
    extraOptions: select.data('extraOptions'),
    placeholder: select.data('placeholder'),
    multiple: Boolean(select.prop('multiple')),
  };

  options = _.defaults(options, {
    extraOptions: [],
    theme: "amara",
    minimumResultsForSearch: 8,
    escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
    templateResult: makeTemplateResult(select),
    templateSelection: makeTemplateSelection(select),
    language: {
      searching: function() { return gettext('Searching…');},
      loadingMore: function () { return gettext('Loading more results…'); },
      noResults: function () { return gettext('No results found');},
      inputTooShort: function (args) { return gettext('Type to search…')}
    }
  });


  if(select.data('noResults')) {
    options.language.noResults = function() { return select.data('noResults'); }
  }

  if (select.data('ajax')) {
    options.ajax = ajaxOptions(select);
    options.minimumResultsForSearch = 0;
    options.allowClear = !(select.data('clear') === false);
    if (select.data('ajax-username-multiple')) {
      options.type = 'ajax-username-multiple';
    }
  } else if(select.data('languageOptions')) {
    options.data = Languages.languageChoiceData(select);
    options.type = 'language';
    options.allowClear = select.data('languageOptions').indexOf('null') > -1;
    options.tokenSeparators = [',', ' '];
  } else {
    var blankOptions = $('option', select).filter(function() {
      return !this.value;
    })
    options.allowClear = blankOptions.length > 0;
  }

  if (select.data('minimum-input-length')) {
    options.minimumInputLength = select.data('minimum-input-length');
  }
  options = _.extend(options, {
    dataAdapter: makeDataAdapter(select, options),
    resultsAdapter: makeResultsAdapter(select, options),
    selectionAdapter: makeSelectionAdapter(select, options),
    dropdownAdapter: makeDropdownAdapter(select, options)
  });
  return options;
}


function ajaxOptions(select) {
  return {
    url: select.data('ajax'),
    dataType: 'json',
    delay: 250,
    data: function (params) {
      return {
        q: params.term, // search term
      };
    },
    cache: true
  };
}

function focusInputAfterSelection(select, options) {
  select.on('select2:close', function() {
    select.focus();
  });
}


function clearButtonHack(select, options) {
  // Workaround to prevent clicking the clear button from opening the dialog (see
  // http://stackoverflow.com/questions/29618382/disable-dropdown-opening-on-select2-clear#29688626)
  var unselecting = false;
  select.on('select2:unselecting', function() {
    unselecting = true;
  }).on('select2:opening', function(e) {
    if(unselecting) {
      unselecting = false;
      e.preventDefault();
    }
  });
}

function handleClear(select, options) {
  var clearOption = ['$clear', gettext('Clear')];
  if(options.allowClear) {
    select.on('select2:select', function(evt) {
      updateExtraOptions();
    }).on('select2:selecting', function(evt) {
      if(evt.params.args.data.id == '$clear' || evt.params.args.data.id == '$unset') {
        var event = evt.params.args.data.id;
        if (event == '$clear') {
          if(options.multiple) {
            select.val([]).change();
          } else {
            select.val('').change();
          }
        } else if (event == '$unset') {
          select.val(['$unset']).change();
        }
        select.select2('close');  
        updateExtraOptions();
        evt.preventDefault();
      } else if (options.multiple && select.val().includes('$unset')){
        // ignore additional selections if unset is currently selected
        select.select2('close');  
        updateExtraOptions();
        evt.preventDefault();
      };
    });
    updateExtraOptions();
  }

  function updateExtraOptions() {
      var extraOptions = _.clone(options.extraOptions);
      if(select.val()) {
        // We can't create options with a blank id, so instead we use a special value and handle it in the selecting event
        extraOptions.push(clearOption);
      }
      select.trigger('data:updateExtraOptions', {
        extraOptions: extraOptions
      });
  }
}

function makeSelectionAdapter(select, options) {
  if(options.multiple) {
    var adapter = MultipleSelection;
    // select2 doesn't create the dropdown arrow for multi-selects, so we need to do it ourselves
    adapter = Utils.Decorate(adapter, AddSelectionArrow);
    adapter = Utils.Decorate(adapter, MultipleSelectSingleLine);
  } else {
    var adapter = SingleSelection;
  }
  if (select.data('placeholder')) {
    adapter = Utils.Decorate(adapter, Placeholder);
  }
  adapter = Utils.Decorate(adapter, EventRelay);
  return adapter;
}

function makeDropdownAdapter(select) {
  var adapter = Dropdown;
  adapter = Utils.Decorate(adapter, DropdownSearch);
  adapter = Utils.Decorate(adapter, DropdownSearchPlaceholder);
  adapter = Utils.Decorate(adapter, MinimumResultsForSearch);
  adapter = Utils.Decorate(adapter, CloseOnSelect);
  adapter = Utils.Decorate(adapter, AttachBody);

  return adapter;
}

/*
 * Decorator for multiple select
 * -- render selections in one line
 * -- when the selections exceed the width of the selector, it gets clipped 
 *    and instead the total number of selections are shown
 */
function MultipleSelectSingleLine() {
    // the element to indicate how many total selections are currently selected
    this.div = null;
}

MultipleSelectSingleLine.prototype.update = function(decorated, data) {
    var max_choices = this.$element.data('max-allowed-choices')

    this.clear(); 

    if (data.length === 0) {
        if(this.div) {
            this.div.remove();
            this.div = null;
        }
        return;
    }
    for(var d=0; d < data.length; d++) {
      if(data[d].id === '$unset') {
        data = [data[d]];
        break;
      }
    }

    var $selections_rendered = this.$selection.find('.select2-selection__rendered')
    if(!this.div) {
        this.div = $('<div class="select2-selection__choice__count">');
        $selections_rendered.after(this.div)
    }
    if(max_choices) {
        this.div.text(interpolate(gettext('%(count)s / %(max_choices)s selected'), {count: data.length, max_choices: max_choices}, true));
    } else {
        this.div.text(interpolate(gettext('%(count)s selected'), {count: data.length}, true));
    }

    var $selections = [];

    for (var d = 0; d < data.length; d++) {
      var selection = data[d];

      var formatted = this.display(selection);
      var $selection = this.selectionContainer();

      $selection.append(formatted);
      $selection.data('data', selection);
      $selections.push($selection);
    }

    $selections_rendered.append($selections);
}

// the element needs to be in the DOM for it to have a width
// `display` is the css display that the element should assume when being measured
function measure_width(element, container, display) {
  el = element.clone();
  el.css('display', display)
  el.css('visibility','hidden');
  el.css('position', 'absolute');
  container.append(el);
  width = el.outerWidth(true)
  el.remove()
  return width
}

function AddSelectionArrow() { }
AddSelectionArrow.prototype.render = function (decorated) {
  var $selection = decorated.call(this);
  $selection.append($(
    '<span class="select2-selection__arrow" role="presentation">' +
    '<b role="presentation"></b>' +
    '</span>'
  ));
  return $selection;
}

function DropdownSearchPlaceholder() { }
DropdownSearchPlaceholder.prototype.render = function (decorated) {
  var $rendered = decorated.call(this);
  this.$search.attr('placeholder', gettext('Type to search…'));
  return $rendered;
}

function addContainerClasses(select, options) {
  var container = select.data('select2').$container;
  if(options.multiple) {
    container.addClass('multiple');
    container.find('.select2-selection__rendered').addClass('select2-selection__rendered__multiple')
  }
  if(select.hasClass('selectFilter')) {
    container.addClass('selectFilter');
  }
}


var priceRegex = /(.*?)--(.*)/;

function makeTemplateResult(select) {
  if(select.hasClass('price')) {
    return templatePrice;
  } else {
    return defaultTemplateResult;
  }
}

function makeTemplateSelection(select) {
  if(select.hasClass('price')) {
    return templatePrice;
  } else {
    return defaultTemplateSelection
  }
}

function defaultTemplateResult(data) {
  var text = _.escape(data.text);
  if(data.avatar) {
    return data.avatar + text;
  } else {
    return text;
  }
}

function defaultTemplateSelection(data) {
  var text = _.escape(data.text);
  if(data.invalid) {
    return $('<span class="text-amaranth"><span class="fas fa-exclamation-triangle"></span> ' + text + '</span>')
  } else {
    return text;
  }
}

function templatePrice(data, container) {
  var match = data.text.match(priceRegex);
  if(match === null) {
    return data.text;
  }
  return (
    $('<span class="divide">')
    .append($('<span>').text(match[1]))
    .append($('<span class="secondary">').text(match[2]))
  );
}

$.behaviors('.select', initSelect);

module.exports = {
    initSelect: initSelect,
    reloadSelect: reloadSelect
};
