require('jquery');
require('waypoints');
require('inview');
var gsap = require('gsap');

function getTime() {
    return new Date().getTime();
}


$('.illustration').each(function() {
    var elt = $(this);
    var startTime = getTime();
    function shouldSkipTrigger() {
        // For the logo animation, don't trigger the animation close to the
        // start of page load.  We're already animating it with the code below.
        return elt.hasClass('logo-animation') && (getTime() - startTime) < 2000;
    }

    new Waypoint.Inview({
        element: this,
        enter: function(direction) {
            if(!shouldSkipTrigger()) {
                // window.console.log(this.element, 'enter triggered');
                $(this.element).find('img').attr('class', 'animated fadeInUp');
            }
        },
        entered: function(direction) {
            if(!shouldSkipTrigger()) {
                // window.console.log(this.element, 'entered triggered');
                $(this.element).find('svg').attr('class', 'animated pulse');
            }
        },
        exit: function(direction) {
            // window.console.log(this.element, 'exit triggered');
            $(this.element).find('svg').attr('class', 'animated');
            $(this.element).find('img').attr('class', 'animated');
        }
    });
});

$('.logo-animation').each(function() {
    var container = $(this);

    var tl = gsap.gsap.timeline();

    // Show the background symbols (the grayscale ones):
    //   - Fade them in over 1.8s
    //   - Scale them up to 1.15 over the first 0.9s, then back down to 1.0 over the next 0.9s
    //   - Stagger each animation by 0.1s
    tl.set(container.find('#background-symbols .symbol'), {scale: 0.6, opacity: 0.0, stagger: 0.1});
    tl.to(container.find('#background-symbols .symbol'), {duration: 0.9, scale: 1.15, opacity: 0.5, stagger:0.1}, 0);
    tl.to(container.find('#background-symbols .symbol'), {duration: 0.9, scale: 1.0, opacity: 1.0, stagger:0.1}, 0.9);

    // Show the finale symbols (colored symbols that appear over the background symbols).
    //    - Remove the finale class to cause them to be displayed
    //    - Stagger the reveals by 0.1s
    container.find('.finale').each(function(i, elt) {
        tl.call(function() {
            $(elt).removeClass('finale')
        }, null, 2 + (i * 0.1));
    });

    // Rotate finale symbols to their final positions
    tl.to('#final-symbols', 2, {rotation:-40, scale: 0.7, x: 15, y: -80, svgOrigin:"450 300"}, 3.8);
    // fade out the background symbols
    tl.to('#background-symbols', 1.5, {opacity: 0, scale:.2, transformOrigin:"300 300"}, "<");
    // Fade in the hands image
    tl.to("#hands", 1, {opacity:1}, "<");
});
