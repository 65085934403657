// Copyright 2019 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');

$.fn.extend({
    formSubmitButton: function() {
        return this.one('click', onSubmitButtonClick);
    }
});


$.behaviors('.formSubmit', function(submitButton) {
    $(submitButton).formSubmitButton();
});

function onSubmitButtonClick(evt) {
    submitButton = $(this);
    var form = submitButton.closest('form');
    if(form.data('formSubmitActive')) {
        evt.preventDefault();
        return;
    }
    form.on('submit ajax-submit', onFormSubmit);
    form.on('ajax-submit-complete', onFormSubmitComplete);
    form.data('formSubmitButton', submitButton);
}

function onFormSubmit(evt) {
    submitButton = $(this).find(':submit')

    disableSubmitButton($(this).data('formSubmitButton'));
    if(!evt.isDefaultPrevented() || submitButton.hasClass("spinOnSubmit")) {
        startFormSubmit($(this));
    }
}

function onFormSubmitComplete(evt) {
    endFormSubmit($(this));
}

function startFormSubmit(form) {
    addSpinner(form);
    form.data('formSubmitActive', true);
}

function endFormSubmit(form) {
    var lastTime = form.data('formSubmitSpinnerTime');
    form.data('formSubmitActive', false);
    if(lastTime) {
        // Wait a short while to ensure the user sees the spinner
        var elapsedTime = $.now() - lastTime;
        if(elapsedTime < 500) {
            setTimeout(function() {removeSpinner(form);}, 500-elapsedTime);
        } else {
            removeSpinner(form);
        }
    } else {
        removeSpinner(form);
    }
    enableSubmitButton(form.data('formSubmitButton'));
}

function addSpinner(form) {
    var submitButton = form.data('formSubmitButton');
    if(submitButton) {
        submitButton.find('.spinner').remove();
        submitButton.prepend($('<span class="spinner"><span class="fas fa-circle-notch fa-spin"></span> </span>'));
    }
    form.data('formSubmitSpinnerTime', $.now());
}

function removeSpinner(form) {
    var submitButton = form.data('formSubmitButton');
    if(submitButton) {
        submitButton.find('.spinner').remove();
    }
}

function disableSubmitButton(submitButton){
    submitButton.attr('disabled', 'disabled');
    submitButton.addClass('disabled');
}

function enableSubmitButton(submitButton){
    submitButton.removeAttr('disabled');
    submitButton.removeClass('disabled');
}

module.exports = {
  start: startFormSubmit,
  end: endFormSubmit
}
