// Copyright 2020 Participatory Culture Foundation, All Rights Reserved

var $ = require('jquery');
var ajax = require('./ajax');

$.behaviors('.multiStepPane', function(multiStepPane) {
  multiStepPane = $(multiStepPane);

  if(window.location.hash) {
    // If the hash is set, try to navigate to that step
    var step = multiStepPane.find('.multiStepPane-step' + window.location.hash);
    if(step.length > 0) {
      multiStepPane.find('.multiStepPane-step.active').removeClass('active');
      step.addClass('active');
      updateLastStep(multiStepPane, step);
    }
  }
});


$.behaviors('.multiStepPane-next', function(elt) {
  elt = $(elt);
  elt.on('click', function(e) {
    gotoNext();
    if (elt.data('auto-scroll')) {
      autoScrollUp();
    }
    e.preventDefault();
  });
});

$.behaviors('.multiStepPane-goto', function(elt) {
  elt = $(elt);
  elt.on('click', function(e) {
    var step = elt.data('step');
    if(step) {
      gotoStep(step);
      if (elt.data('auto-scroll')) {
        autoScrollUp();
      }
      e.preventDefault();
    }
  });
});

function autoScrollUp() {
  $(window).scrollTop(0);
}

function gotoStep(step) {
  var pane = $('.multiStepPane').first();
  switchStep(pane, $('#' + step));
}

function gotoNext() {
  var pane = $('.multiStepPane').first();
  var nextStep = pane.find('.multiStepPane-step.active').next('.multiStepPane-step');
  switchStep(pane, stepOrLastStep(pane, nextStep));
}

function gotoElement(elt) {
  var step = elt.closest('.multiStepPane-step');
  var pane = step.closest('.multiStepPane');
  switchStep(pane, step);
}

function switchStep(pane, step) {
  var fadeDuration = 400;
  var current = pane.find('.multiStepPane-step.active');
  function fadeInNewStep() {
    step.addClass('active').fadeIn({duration: fadeDuration})
    updateLastStep(pane, step);
    if(step.attr('id')) {
      history.replaceState(null, "", '#' + step.attr('id'));
    }
  }
  if(current) {
    current.fadeOut({
      duration: fadeDuration,
      done: function() {
        current.removeClass('active');
        fadeInNewStep();
      }
    });
  } else {
    fadeInNewStep();
  }
}

function updateLastStep(pane, step) {
  pane.data('lastStep', stepOrLastStep(pane, step));
}

/**
 * Pick a furthest step between a particular step and the lastStep stored in the pane
 */
function stepOrLastStep(pane, step) {
  var lastStep = pane.data('lastStep');
  if(lastStep && lastStep.prevAll().is(step)) {
    return lastStep;
  } else {
    return step;
  }
}

Object.assign(ajax.responseProcessors, {
  'multiStepPaneGotoStep': function(change) {
    gotoStep(change[1]);
  },
  'multiStepPaneGotoNext': function(change) {
    gotoNext();
  }
});

module.exports = {
  gotoStep: gotoStep,
  gotoNext: gotoNext,
  gotoElement: gotoElement
};
