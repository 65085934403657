require("jquery");

var multiStepPane = require('../shared/multiStepPane');

var removed_hover = false;

$.behaviors('.aod-service-container', function(container) {
    var container = $(container);

    container.hover(function() {
        if (!removed_hover) {
            $('input[name="aod-service"]:checked').removeAttr('checked');
            removed_hover = true;
        }
    })
})

$.behaviors('.featured-services-button', function(btn) {
    btn.on('click', function() {
        $('html, body').animate({
            scrollTop: $('#formScroll').offset().top
        }, 0);
        var step = btn.data('step');
        multiStepPane.gotoStep(step);
    });
});

$.behaviors('.aod-service-link', function(link) {
    $(link).on('click', function(event) {
        event.preventDefault(); // Prevent the default link behavior

        // Get the target element's ID from the link's href attribute
        var href = $(this).attr('href');

        // Extract the ID part after the hash (#)
        var targetId = href.split('#')[1];

        // Animate scroll to the target element
        $('html, body').animate({
            scrollTop: $('#' + targetId).offset().top
        }, 1000); // You can adjust the duration of the scroll animation as needed

        // Get the data-step attribute value from the clicked link
        var step = $(this).data('step');

        // Do something with the step value, e.g., navigate to a specific step
        multiStepPane.gotoStep(step);
    });
});


$.behaviors('.request-language-link', function(link) {
    var link = $(link);

    link.on('click', function(event) {
        // Store language selection in a URL parameter
        var language = link.data("language");
        var formPageUrl;
        if (window.location.href.indexOf("/video-captions/") !== -1) {
            // For "/video-captions/" page, redirect to the form page with "step2-captions" as step parameter
            formPageUrl = "/purchase-subtitles/?language=" + encodeURIComponent(language) + "&step=step2-captions";
        } else {
            // For other pages, redirect to the form page with "step2-translations" as step parameter
            formPageUrl = "/purchase-subtitles/?language=" + encodeURIComponent(language) + "&step=step2-translations";
        }

        if (formPageUrl) {
            // Prevent default link behavior to allow redirection
            event.preventDefault();
            // Redirect user to the form page with language and step parameters
            window.location.href = formPageUrl;
        }
    });
});

// On form page load, check for the language and step parameters in the URL and apply selections accordingly
$(document).ready(function() {
    var urlParams = new URLSearchParams(window.location.search);
    var selectedLanguage = urlParams.get('language');
    var step = urlParams.get('step');

    if (selectedLanguage && step) {
        // Scroll to the form
        $('html, body').animate({
            scrollTop: $('#formScroll').offset().top
        }, 0);
        // Perform step transition
        multiStepPane.gotoStep(step);
        // Apply language selection to appropriate field based on step
        if (step === "step2-captions") {
            var selector = $('#' + step + ' > form').find('#id_source_languages');
        } else {
            var selector = $('#' + step + ' > form').find('#id_target_languages');
        }
        var selected_languages = selector.val() || [];
        selected_languages.push(selectedLanguage);
        selector.val(selected_languages).trigger("change");
    }
});



// Random display of subtitler in "meet AOD subtitler" section AOD page
$(function() {
    var rand = Math.floor(Math.random()*2);
    var visibleDiv = $('.random-subtitler')[rand];
    $(visibleDiv).show();
});

$.behaviors('.aod-order-form', function(form) {
    var form = $(form);

    form.on('submit', function(evt) {
        evt.preventDefault();
        var form_data = new FormData(evt.target);

        var source_languages = [];
        var target_languages = [];

        for ([key, value] of form_data.entries()) {
            if (key == 'csrfmiddlewaretoken') {
                continue;
            }

            var selector = '#aod-order-form-comms-' + form.data('inquiryType') + ' input[name="' + key + '"]';

            // serialize source and taret languages because hidden inputs cant accomodate arrays
            if (key == 'source_languages') {
                source_languages.push(value);
                $(selector).val(source_languages.join())
            } else if (key == 'target_languages') {
                target_languages.push(value);
                $(selector).val(target_languages.join())
            } else {
                $(selector).val(value);
            }
        }

        var next_step = form.find('button[type="submit"]').data('step');
        multiStepPane.gotoStep(next_step);


    });
});

$.behaviors('.aod-how-did-you-find-us', function(selector) {
    var selector = $(selector);

    selector.on('change', function() {
        new_value = this.value;
        show_target = $(selector.data('show-target'));
        if (new_value == 'Other') {
            show_target.show();
            show_target.prop('required', true);
        } else {
            show_target.hide();
            show_target.prop('required', false);
        }
    });
});

var step1Text = $('.step1');
var step2Text = $('.step2');
var step3Text = $('.step3');
var bgstep1 = $('.bg-step1');
var bgstep2 = $('.bg-step2');
var bgstep3 = $('.bg-step3');
var step1Vid = $('.videostep1');
var step2Vid = $('.videostep2');
var step3Vid = $('.videostep3');


var aodServiceText1 = $('.aodServiceText1');
var aodServiceText2 = $('.aodServiceText2');
var aodServiceText3 = $('.aodServiceText3');
var aodServiceText4 = $('.aodServiceText4');
var aodServiceText5 = $('.aodServiceText5');
var bgaodService1 = $('.bg-aodService1');
var bgaodService2 = $('.bg-aodService2');
var bgaodService3 = $('.bg-aodService3');
var bgaodService4 = $('.bg-aodService4');
var bgaodService5 = $('.bg-aodService5');

$('#video1').trigger('play');
$('#video2').trigger('play');

$('#video1').bind("ended",function(e){
    $('#video2').get(0).currentTime = 0;
    $('#video2').trigger('play');
    $('#video3').trigger('stop');
    $('#video1').trigger('stop');
    bgstep1.removeClass('visible');
    step1Text.removeClass('active');
    step3Text.removeClass('active');
    step1Vid.removeClass('play');
    step3Vid.removeClass('play');
    step2Text.addClass('active');
    bgstep2.addClass('visible');
    step2Vid.addClass('play');
})
$('#video2').bind("ended",function(e){
    $('#video3').get(0).currentTime = 0;
    $('#video3').trigger('play');
    $('#video1').trigger('stop');
    $('#video2').trigger('stop');
    bgstep2.removeClass('visible');
    step1Text.removeClass('active');
    step2Text.removeClass('active');
    step1Vid.removeClass('play');
    step2Vid.removeClass('play');
    bgstep3.addClass('visible');
    step3Text.addClass('active');
    step3Vid.addClass('play');
})
$('#video3').bind("ended",function(e){
    $('#video1').get(0).currentTime = 0;
    $('#video1').trigger('play');
    $('#video2').trigger('stop');
    $('#video3').trigger('stop');
    bgstep3.removeClass('visible');
    step2Text.removeClass('active');
    step3Text.removeClass('active');
    step3Vid.removeClass('play');
    step2Vid.removeClass('play');
    bgstep1.addClass('visible');
    step1Text.addClass('active');
    step1Vid.addClass('play');
})

$('.step1').on('click',function(){
    bgstep1.addClass('visible');
    bgstep2.removeClass('visible');
    bgstep3.removeClass('visible');
    $('#video2').trigger('pause');
    $('#video3').trigger('pause');
    $('#video1').trigger('play');
    $('#video1').get(0).currentTime = 0;
    step2Text.removeClass('active');
    step3Text.removeClass('active');
    step1Text.addClass('active');
    step3Vid.removeClass('play');
    step2Vid.removeClass('play');
    step1Vid.addClass('play');
});

$('.step2').on('click',function(){
    bgstep2.addClass('visible');
    bgstep1.removeClass('visible');
    bgstep3.removeClass('visible');
    $('#video1').trigger('pause');
    $('#video3').trigger('pause');
    $('#video2').trigger('play');
    $('#video2').get(0).currentTime = 0;
    step1Text.removeClass('active');
    step3Text.removeClass('active');
    step2Text.addClass('active');
    step1Vid.removeClass('play');
    step3Vid.removeClass('play');
    step2Vid.addClass('play');
});

$('.step3').on('click',function(){
    bgstep3.addClass('visible');
    bgstep1.removeClass('visible');
    bgstep2.removeClass('visible');
    $('#video1').trigger('pause');
    $('#video2').trigger('pause');
    $('#video3').trigger('play');
    $('#video3').get(0).currentTime = 0;
    step1Text.removeClass('active');
    step2Text.removeClass('active');
    step3Text.addClass('active');
    step1Vid.removeClass('play');
    step2Vid.removeClass('play');
    step3Vid.addClass('play');
});


$('.aodServiceText1').on('click',function(){
    bgaodService1.addClass('visible');
    bgaodService2.removeClass('visible');
    bgaodService3.removeClass('visible');
    bgaodService4.removeClass('visible');
    bgaodService5.removeClass('visible');
    aodServiceText2.removeClass('active');
    aodServiceText3.removeClass('active');
    aodServiceText4.removeClass('active');
    aodServiceText5.removeClass('active');
    aodServiceText1.addClass('active');
});

$('.aodServiceText2').on('click',function(){
    bgaodService2.addClass('visible');
    bgaodService1.removeClass('visible');
    bgaodService3.removeClass('visible');
    bgaodService4.removeClass('visible');
    bgaodService5.removeClass('visible');
    aodServiceText1.removeClass('active');
    aodServiceText3.removeClass('active');
    aodServiceText4.removeClass('active');
    aodServiceText5.removeClass('active');
    aodServiceText2.addClass('active');
});

$('.aodServiceText3').on('click',function(){
    bgaodService3.addClass('visible');
    bgaodService1.removeClass('visible');
    bgaodService2.removeClass('visible');
    bgaodService4.removeClass('visible');
    bgaodService5.removeClass('visible');
    aodServiceText1.removeClass('active');
    aodServiceText2.removeClass('active');
    aodServiceText4.removeClass('active');
    aodServiceText5.removeClass('active');
    aodServiceText3.addClass('active');
});
$('.aodServiceText4').on('click',function(){
    bgaodService4.addClass('visible');
    bgaodService1.removeClass('visible');
    bgaodService2.removeClass('visible');
    bgaodService3.removeClass('visible');
    bgaodService5.removeClass('visible');
    aodServiceText1.removeClass('active');
    aodServiceText2.removeClass('active');
    aodServiceText3.removeClass('active');
    aodServiceText5.removeClass('active');
    aodServiceText4.addClass('active');
});
$('.aodServiceText5').on('click',function(){
    bgaodService5.addClass('visible');
    bgaodService1.removeClass('visible');
    bgaodService2.removeClass('visible');
    bgaodService3.removeClass('visible');
    bgaodService4.removeClass('visible');
    aodServiceText1.removeClass('active');
    aodServiceText2.removeClass('active');
    aodServiceText3.removeClass('active');
    aodServiceText4.removeClass('active');
    aodServiceText5.addClass('active');
});

